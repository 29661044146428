import * as React from "react";
import { Link } from "gatsby";

import Bridge from "../../components/Bridge.js";
import Ch from "../../components/Chord.js";
import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
import Verse from "../../components/Verse.js";

export default function DontPanic() {
  return (
    <Layout>
      <Helmet title="Don't Panic | Coldplay" />

      <h1>Don't Panic</h1>

      <h2>Coldplay</h2>

      <h4>Key: F</h4>

      <h4>Tempo: 124</h4>

      <Link to="/">List</Link>

      <Columns columns="3">
        <Verse>
          <p>
            <Ch>Am</Ch>Bones, sinking like <Ch>C</Ch>stones
          </p>
          <p>
            All that we <Ch>Fmaj7</Ch>fought for
          </p>
          <p>
            <Ch>Am</Ch>Homes, places we've <Ch>C</Ch>grown
          </p>
          <p>
            All of us are <Ch>Fmaj7</Ch>done for
          </p>
        </Verse>
        <Chorus>
          <p>
            <Ch>Dm</Ch>And we live in a <Ch>Dm6</Ch>beautiful world<Ch>Am</Ch>
          </p>
          <p>
            Yeah we do, <Ch>G</Ch>yeah we do,
          </p>
          <p>
            <Ch>Dm</Ch>We live in a <Ch>Dm6</Ch>beautiful world,<Ch>Fmaj7</Ch>
          </p>
        </Chorus>
        <Verse>
          <p>
            <Ch>Am</Ch>Bones, sinking like <Ch>C</Ch>stones
          </p>
          <p>
            All that we <Ch>Fmaj7</Ch>fought for
          </p>
          <p>
            <Ch>Am</Ch>Homes, places we've <Ch>C</Ch>grown
          </p>
          <p>
            All of us are <Ch>Fmaj7</Ch>done for
          </p>
        </Verse>
        <Chorus>
          <p>
            <Ch>Dm</Ch>And we live in a <Ch>Dm6</Ch>beautiful world<Ch>Am</Ch>
          </p>
          <p>
            Yeah we do, <Ch>G</Ch>yeah we do,
          </p>
          <p>
            <Ch>Dm</Ch>We live in a <Ch>Dm6</Ch>beautiful world,<Ch>Fmaj7</Ch>
          </p>
        </Chorus>
        <Bridge>
          <p>
            <b>Am C Fmaj7</b>
          </p>
          <p>
            <b>Am C Fmaj7</b>
          </p>
        </Bridge>
        <Chorus>
          <p>
            <Ch>Dm</Ch>And we live in a <Ch>Dm6</Ch>beautiful world<Ch>Am</Ch>
          </p>
          <p>
            Yeah we do, <Ch>G</Ch>yeah we do,
          </p>
          <p>
            <Ch>Dm</Ch>We live in a <Ch>Dm6</Ch>beautiful world,<Ch>Fmaj7</Ch>
          </p>
        </Chorus>
        <Verse>
          <p>
            <Ch>Am</Ch>Oh, all that I <Ch>C</Ch>know
          </p>
          <p>
            There's nothing here to run <Ch>Fmaj7</Ch>from
          </p>
          <p>
            Cos <Ch>Am</Ch>yeah, everybody <Ch>C</Ch>here got somebody to{" "}
            <Ch>Fmaj7</Ch>lean on
          </p>
        </Verse>
      </Columns>
    </Layout>
  );
}
